// Cursor.js
import React, { useState, useEffect, useRef } from 'react';
import './animations.css';

const Cursor = () => {
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [isClicked, setIsClicked] = useState(false);
    const [isTextHovered, setIsTextHovered] = useState(false);
    const [hoveredElement, setHoveredElement] = useState(null);
    const cursorRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const elements = document.querySelectorAll('.magnetic');
            let foundHover = null;

            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;

                if (
                    e.clientX >= rect.left &&
                    e.clientX <= rect.right &&
                    e.clientY >= rect.top &&
                    e.clientY <= rect.bottom
                ) {
                    foundHover = element;
                    const deltaXEmission = (e.clientX - centerX) / 10;
                    const deltaYEmission = (e.clientY - centerY) / 10;
                    const deltaXContent = (e.clientX - centerX) / 20;
                    const deltaYContent = (e.clientY - centerY) / 20;

                    const emission = element.querySelector('.magnetic_emission');
                    const content = emission.querySelector('.magnetic_content');

                    emission.style.transform = `translate(${deltaXEmission}px, ${deltaYEmission}px) scale(1.05)`;
                    content.style.transform = `translate(${deltaXContent}px, ${deltaYContent}px)`;
                } else {
                    const emission = element.querySelector('.magnetic_emission');
                    const content = emission.querySelector('.magnetic_content');

                    emission.style.transform = 'translate(0, 0) scale(1)';
                    content.style.transform = 'translate(0, 0)';
                }
            });

            if (foundHover) {
                setHoveredElement(foundHover);
                const rect = foundHover.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;

                const newX = e.clientX + (centerX - e.clientX) * 0.1;
                const newY = e.clientY + (centerY - e.clientY) * 0.1;
                setCursorPos({ x: newX, y: newY });
            } else {
                setHoveredElement(null);
                setCursorPos({ x: e.clientX, y: e.clientY });
            }
        };

        const handleMouseDown = () => {
            if (cursorRef.current) {
                cursorRef.current.classList.remove('cursor-clicked');
                void cursorRef.current.offsetWidth; // Перезапуск анимации
                cursorRef.current.classList.add('cursor-clicked');
            }
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 500); // Сбросить состояние после завершения анимации
        };

        const handleMouseOverText = () => setIsTextHovered(true);
        const handleMouseOutText = () => setIsTextHovered(false);

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mousedown', handleMouseDown);
        document.querySelectorAll('p, span, a').forEach((el) => {
            el.addEventListener('mouseover', handleMouseOverText);
            el.addEventListener('mouseout', handleMouseOutText);
        });

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mousedown', handleMouseDown);
            document.querySelectorAll('p, span, a').forEach((el) => {
                el.removeEventListener('mouseover', handleMouseOverText);
                el.removeEventListener('mouseout', handleMouseOutText);
            });
        };
    }, []);

    return (
        <div
            ref={cursorRef}
            className={`cursor ${hoveredElement ? 'cursor-hovered' : ''} ${isClicked ? 'cursor-clicked' : ''} ${isTextHovered ? 'cursor-text-hovered' : ''}`}
            style={{ left: `${cursorPos.x}px`, top: `${cursorPos.y}px` }}
        ></div>
    );
};

export default Cursor;
