// Window4.js
import React, { useState, useEffect } from 'react';
import './Window4.css';

const Window4 = ({ offsetX, offsetY, zoom }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1000) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    }, [windowWidth]);

    return (
        <div
            className="window4"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <p className="window_name"
               style={{
                   position: "fixed",
                   top: "-20px",
                   left: "4px",
                   fontSize: "12px",
                   color: "white",
                   opacity: "0.5",
               }}>
                Amore Mio – 2023
            </p>
            <img
                src={require(isHovered ? './images/amore_mio.png' : './images/transparent_amore_mio.png')}
                className={`save_proportions`}
                alt="chat"
            />
            <div className="window4_inner" >
                <h1>Amore Mio – магазин свечей</h1>
                <p>апрель - июль 2023</p>
                <p>За два месяца подготовил сайт на разработку. По своей инициативе успел провести тестирование на ~50 клиентах. Работа была больше визуальная, что гораздо больше ценилось заказчицей.</p>
            </div>
        </div>
    );
};

export default Window4;
