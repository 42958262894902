// Window3.js
import React, { useState, useEffect } from 'react';
import './Window3.css';
import PoliceTape from './PoliceTape';

const OtherPortfolio = ({ offsetX, offsetY, zoom }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1000) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    }, [windowWidth]);

    return (
        <div
            className="window3"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left',
                width: `300px`,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <p className="window_name"
               style={{
                   position: "fixed",
                   top: "-20px",
                   left: "4px",
                   fontSize: "12px",
                   color: "white",
                   opacity: "0.5",
               }}>
                Остальное портфолио
            </p>
            <PoliceTape className="plctp" />
            <div className="window3_inner" >
                <h1>Портфолио</h1>
                <p>Остальное портфолио – в разработке</p>
            </div>
        </div>
    );
};

export default OtherPortfolio;
