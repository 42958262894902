// Window1.js
import React, { useEffect } from 'react';
import './Window1.css';

const Window1 = ({ offsetX, offsetY, zoom }) => {
    useEffect(() => {
        // JS код для ячеек таблицы
        const cells = document.querySelectorAll('td');
        cells.forEach(cell => {
            cell.addEventListener('mouseenter', () => {
                cells.forEach(c => {
                    if (c !== cell) {
                        c.style.opacity = '0.6';
                    }
                });
                cell.style.opacity = '1';
            });

            cell.addEventListener('mouseleave', () => {
                cells.forEach(c => {
                    c.style.opacity = '1';
                });
            });
        });
    }, []);

    return (
        <div
            className="window"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left'
            }}
        >
            <p className="window_name"
                style={{
                position: "fixed",
                top: "-20px",
                left: "4px",
                fontSize: "12px",
                color: "white",
                opacity: "0.5",
            }}
            >Обо мне</p>

            <div id="shapka1">
                <div id="grdnt1"></div>
            </div>

            <div id="avatar_position">
                <div id="avatar">
                </div>
            </div>

            <h1>Вячеслав Сергеев</h1>
            <p>UX/UI Designer • Санкт-Петербург • Брянск<br />СПбГУТ • 3 курс • ИСиТ</p>
            <p>тг: <a href="https://t.me/betray32">@betray32</a> • вк: <a href="https://vk.com/idslavaid">Vyacheslav Sergeev</a></p>

            <div className="block-horizontal">
                <h4>Супер боевой джун, готовый встряхнуть мир дизайна. Не только владею всеми необходимыми инструментами, но и могу превосходить ожидания, превращая каждый проект в произведение технологического искусства. А ещё я обожаю создавать сложные системы.</h4>
            </div>

            <div className="block-vertical">
                <h4>Ключевые моменты</h4>
                <table id="myTable">
                    <tbody>
                    <tr>
                        <td>
                            <blockquote>
                                <p><b>Гладкий Handoff-Процесс:</b> стремлюсь к безупречной передаче дизайнов, упрощая жизнь разработчикам. Благодаря навыкам в вёрстке, дизайны легко интегрируются в код, обеспечивая плавный переход от визуала к функционалу.</p>
                            </blockquote>
                        </td>
                        <td>
                            <blockquote>
                                <p><b>Разносторонний Подход:</b> От вёрстки до motion-дизайна и 3D-моделирования — обоснованно использую широкий арсенал инструментов для создания захватывающих веб-проектов, где каждый элемент служит определенной цели и улучшает пользовательский опыт.</p>
                            </blockquote>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <blockquote>
                                <p><b>Глубоко погружаюсь в понимание пользовательского опыта (UX)</b>. Достигаю бизнес-целей заказчика и сердечек пользователей.</p>
                            </blockquote>
                        </td>
                        <td>
                            <blockquote>
                                <p><b>Мой главный интерес</b> — не разрабатывать веб-сайты и втупую получать денюжки, а <b>создавать эффективные цифровые решения</b>, познавая новые важные фишки в сфере.</p>
                            </blockquote>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Window1;
