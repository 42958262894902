// Sidebar.js
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ onSelectPage }) => {
    const sidebarRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startDragX, setStartDragX] = useState(0);
    const [startDragY, setStartDragY] = useState(0);
    const [posX, setPosX] = useState(50);
    const [posY, setPosY] = useState(50);
    const [selectedPage, setSelectedPage] = useState("Обо мне");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const page = location.pathname.substring(1);
        switch (page) {
            case "about":
                setSelectedPage("Обо мне");
                break;
            case "skills":
                setSelectedPage("Навыки");
                break;
            case "chatmanager":
                setSelectedPage("Чат-менеджер – 2024");
                break;
            case "tedu":
                setSelectedPage("Т-Образование – 2024");
                break;
            case "amoremio":
                setSelectedPage("Amore Mio – 2023");
                break;
            case "scoffee":
                setSelectedPage("SCoffee – 2023");
                break;
            default:
                setSelectedPage("Обо мне");
        }
    }, [location]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartDragX(e.clientX - posX);
        setStartDragY(e.clientY - posY);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newPosX = e.clientX - startDragX;
            const newPosY = e.clientY - startDragY;
            setPosX(newPosX);
            setPosY(newPosY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            setIsDragging(true);
            setStartDragX(e.touches[0].clientX - posX);
            setStartDragY(e.touches[0].clientY - posY);
        }
    };

    const handleTouchMove = (e) => {
        if (isDragging && e.touches.length === 1) {
            const newPosX = e.touches[0].clientX - startDragX;
            const newPosY = e.touches[0].clientY - startDragY;
            setPosX(newPosX);
            setPosY(newPosY);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const handleMouseMoveGlobal = (e) => handleMouseMove(e);
        const handleMouseUpGlobal = () => handleMouseUp();

        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMoveGlobal);
            document.addEventListener('mouseup', handleMouseUpGlobal);
        } else {
            document.removeEventListener('mousemove', handleMouseMoveGlobal);
            document.removeEventListener('mouseup', handleMouseUpGlobal);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveGlobal);
            document.removeEventListener('mouseup', handleMouseUpGlobal);
        };
    }, [isDragging]);

    useEffect(() => {
        const sidebar = sidebarRef.current;

        sidebar.addEventListener('touchmove', handleTouchMove, { passive: false });
        sidebar.addEventListener('touchend', handleTouchEnd);

        return () => {
            sidebar.removeEventListener('touchmove', handleTouchMove);
            sidebar.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging, posX, posY]);

    const handlePageChange = (page) => {
        setSelectedPage(page);
        onSelectPage(page);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div
            ref={sidebarRef}
            className={`sidebar ${isCollapsed ? 'sidebar-hidden' : ''}`}
            style={{ transform: `translate(${posX}px, ${posY}px)` }}
        >
            <div
                className="sidebar-header"
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
            >
                <span className="sidebar-title">Страницы</span>
                <div style={{ display: "flex", flexDirection: "row", gap: "0" }}>
                    <div style={{ paddingRight: "0px" }} className="magnetic">
                        <div style={{ borderRadius: "6px" }} className="magnetic_emission">
                            <div className="magnetic_content">
                                <button
                                    className={`sidebar-button collapse-panel ${isCollapsed ? 'collapsed' : ''}`}
                                    onClick={toggleCollapse}
                                >
                                    <svg
                                        className={`collapse-icon ${isCollapsed ? 'collapsed' : ''}`}
                                        width="18"
                                        height="10"
                                        viewBox="0 0 18 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M1 1L9.2963 8L17 1" stroke="#D9D9D9" strokeWidth="2" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingRight: "2px", paddingLeft: "2px" }} className="magnetic">
                        <div style={{ borderRadius: "6px" }} className="magnetic_emission">
                            <div className="magnetic_content">
                                <button
                                    className="sidebar-button"
                                    onMouseDown={handleMouseDown}
                                    onTouchStart={handleTouchStart}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 4L10 0L6 4L9 4V9H4V6L0 10L4 14V11H9V16H6L10 20L14 16H11V11H16V14L20 10L16 6V9H11V4L14 4Z" fill="#D9D9D9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`sidebar-content ${isCollapsed ? 'hidden' : ''}`}>
                {["Обо мне", "Навыки", "Чат-менеджер – 2024", "Т-Образование – 2024", "Amore Mio – 2023", "SCoffee – 2023"].map((page) => (
                    <div
                        key={page}
                        style={{ padding: "4px 16px" }}
                        className="magnetic"
                        onClick={() => handlePageChange(page)}
                    >
                        <div className="magnetic_emission">
                            <div className="magnetic_content">
                                <div className={`sidebar-item ${selectedPage === page ? "selected" : ""}`}>
                                    {selectedPage === page && (
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 3.5L3.66667 6L9 1" stroke="white" strokeWidth="2" />
                                        </svg>
                                    )}
                                    <span className="sidebar-item-text">
                                        {page}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
