import React, { useEffect, useRef } from 'react';
import './BackgroundDots.css';

const BackgroundDots = ({ offsetX, offsetY, zoom }) => {
    const canvasRef = useRef(null);
    const rulerCanvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const rulerCanvas = rulerCanvasRef.current;
        const ctx = canvas.getContext('2d');
        const rulerCtx = rulerCanvas.getContext('2d');

        const ratio = window.devicePixelRatio || 1;
        const canvasWidth = 2500;
        const canvasHeight = 2500;
        const viewWidth = window.innerWidth;
        const viewHeight = window.innerHeight;

        canvas.width = canvasWidth * ratio;
        canvas.height = canvasHeight * ratio;
        canvas.style.width = `${canvasWidth}px`;
        canvas.style.height = `${canvasHeight}px`;
        ctx.scale(ratio, ratio);

        rulerCanvas.width = viewWidth * ratio;
        rulerCanvas.height = viewHeight * ratio;
        rulerCanvas.style.width = `${viewWidth}px`;
        rulerCanvas.style.height = `${viewHeight}px`;
        rulerCtx.scale(ratio, ratio);

        const drawDots = () => {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.fillStyle = 'rgba(255, 255, 255, 0.15)';
            const step = 50 * zoom;
            for (let x = -1250; x < 1250; x += step) {
                for (let y = -1250; y < 1250; y += step) {
                    const drawX = (x + 1250 + offsetX % step) * zoom;
                    const drawY = (y + 1250 + offsetY % step) * zoom;
                    ctx.fillRect(drawX, drawY, 2 * zoom, 2 * zoom);
                }
            }
        };

        const drawRulers = () => {
            rulerCtx.clearRect(0, 0, viewWidth, viewHeight);

            // Draw horizontal ruler background
            rulerCtx.fillStyle = 'rgba(33, 33, 33, 1)';
            rulerCtx.fillRect(0, 0, viewWidth, 20);
            rulerCtx.strokeStyle = 'rgb(73,73,73)';
            rulerCtx.strokeRect(0, 0, viewWidth, 20);

            // Draw vertical ruler background
            rulerCtx.fillStyle = 'rgba(33, 33, 33, 1)';
            rulerCtx.fillRect(0, 0, 20, viewHeight);
            rulerCtx.strokeStyle = 'rgb(73,73,73)';
            rulerCtx.strokeRect(0, 0, 20, viewHeight);

            rulerCtx.fillStyle = 'rgb(128,128,128)';
            rulerCtx.font = '10px';

            let step = 50;
            if (zoom < 0.75) {
                step = 100;
            } else if (zoom > 1.5) {
                step = 25;
            }

            // Draw horizontal ruler
            for (let x = Math.floor(-offsetX / step) * step; x < viewWidth / zoom; x += step) {
                const drawX = (x + offsetX) * zoom;
                rulerCtx.fillRect(drawX, 15, 1, 4);
                if (step >= 50 || zoom >= 1) {
                    rulerCtx.fillText((x).toFixed(0), drawX + 3, 10);
                }
            }

            // Draw vertical ruler
            for (let y = Math.floor(-offsetY / step) * step; y < viewHeight / zoom; y += step) {
                const drawY = (y + offsetY) * zoom;
                rulerCtx.fillRect(15, drawY, 4, 1);
                if (step >= 50 || zoom >= 1) {
                    rulerCtx.save();
                    rulerCtx.translate(15, drawY);
                    rulerCtx.rotate(-Math.PI / 2);
                    rulerCtx.fillText((y).toFixed(0), -10, -5);
                    rulerCtx.restore();
                }
            }

            // Draw the square in the top-left corner
            rulerCtx.fillStyle = 'rgba(33, 33, 33, 1)';
            rulerCtx.fillRect(0, 0, 20, 20);
            rulerCtx.strokeStyle = 'rgb(73,73,73)';
            rulerCtx.strokeRect(0, 0, 20, 20);
        };

        drawDots();
        drawRulers();
    }, [offsetX, offsetY, zoom]);

    return (
        <>
            <canvas ref={canvasRef} className="background-canvas"></canvas>
            <canvas ref={rulerCanvasRef} className="ruler-canvas"></canvas>
        </>
    );
};

export default BackgroundDots;
