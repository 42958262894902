import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Helmet } from 'react-helmet';
import CanvasContainer from './CanvasContainer';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import './App.css';
import Cursor from './Cursor';

const App = () => {
    const [currentWindow, setCurrentWindow] = useState("Обо мне");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const page = location.pathname.substring(1);
        switch (page) {
            case "about":
                setCurrentWindow("Обо мне");
                break;
            case "skills":
                setCurrentWindow("Навыки");
                break;
            case "chatmanager":
                setCurrentWindow("Чат-менеджер – 2024");
                break;
            case "tedu":
                setCurrentWindow("Т-Образование – 2024");
                break;
            case "amoremio":
                setCurrentWindow("Amore Mio – 2023");
                break;
            case "scoffee":
                setCurrentWindow("SCoffee – 2023");
                break;
            default:
                setCurrentWindow("Обо мне");
                navigate("/about", { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        if (window.Telegram.WebApp) {
            const webApp = window.Telegram.WebApp;
            webApp.expand(); // Разворачивает Web App на полный экран
            webApp.setHeaderColor("#2C2C2C"); // Устанавливает цвет шапки
            webApp.onEvent('viewportChanged', (e) => {
                if (e.isStateStable) {
                    webApp.expand(); // Поддерживает Web App развернутым
                }
            });
        }
    }, []);

    const handleSelectPage = (page) => {
        setCurrentWindow(page);
        switch (page) {
            case "Обо мне":
                navigate("/about");
                break;
            case "Навыки":
                navigate("/skills");
                break;
            case "Чат-менеджер – 2024":
                navigate("/chatmanager");
                break;
            case "Т-Образование – 2024":
                navigate("/tedu");
                break;
            case "Amore Mio – 2023":
                navigate("/amoremio");
                break;
            case "SCoffee – 2023":
                navigate("/scoffee");
                break;
            default:
                navigate("/about");
        }
    };

    return (
        <div className="app">
            <Helmet>
                <meta property="og:title" content="Резюме и немного портфолио Славы" />
                <meta property="og:image" content="https://napdsgn.ru/ogimage.png" />
                <meta property="og:description" content="Сайт по-прежнему в доработках (21.06.24)" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <Header className="overflowoff" />
            <div className="main-content">
                <Sidebar className="overflowoff" onSelectPage={handleSelectPage} />
                <CanvasContainer currentWindow={currentWindow} />
            </div>
            <Cursor hoveredElement={null} />
        </div>
    );
};

const AppWithRouter = () => (
    <Router>
        <Routes>
            <Route path="/*" element={<App />} />
        </Routes>
    </Router>
);

export default AppWithRouter;