// Window5.js
import React, { useState, useEffect, useRef } from 'react';
import './Window5.css';

const Window5 = ({ offsetX, offsetY, zoom }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const preventPause = (event) => {
            videoElement.play();
        };

        videoElement.addEventListener('pause', preventPause);

        return () => {
            videoElement.removeEventListener('pause', preventPause);
        };
    }, []);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1000) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    }, [windowWidth]);

    return (
        <div
            className="window5"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <p className="window_name"
               style={{
                   position: "fixed",
                   top: "-20px",
                   left: "4px",
                   fontSize: "12px",
                   color: "white",
                   opacity: "0.5",
               }}>
                Т-Банк – 2024
            </p>
            <div className="window5_inner">
                <video
                    ref={videoRef}
                    src={require('./videos/tink1.mp4')}
                    className={`save_proportions`}
                    autoPlay
                    loop
                    muted
                    controls
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px'
                    }}
                />
            </div>
            <div className="window5_outer">
                <h1>Т-Банк – 2024</h1>
                <p><b>Продукт</b> – платформа цифрового офиса</p>
                <p><b>Задача</b> — спроектировать веб интерфейс для управления офисом, который поможет сотрудникам проводить время в офисе с пользой</p>
            </div>
        </div>
    );
};

export default Window5;
