import React from 'react';
import './PoliceTape.css';

const PoliceTape = () => {
    return (
        <div className="police-tape">
            <div className="tape"></div>
        </div>
    );
};

export default PoliceTape;
