import React, { useEffect } from 'react';
import './Window2.css';

const Window2 = ({ offsetX, offsetY, zoom }) => {
    useEffect(() => {
        // Автоматическое раскрытие аккордеона при запуске сайта
        // Раскрытие всех аккордеонов при загрузке страницы
        setTimeout(function() {
            document.querySelectorAll('.accordion2').forEach(acc => {
                acc.classList.add('active');
                const content = acc.querySelector('.accordion-content2');
                content.style.display = 'block';

                // Появление каждого пункта с задержкой
                let delay = 0;
                content.querySelectorAll('li').forEach((item, index) => {
                    setTimeout(() => {
                        item.style.display = 'list-item';
                    }, delay);
                    delay += 70;
                });
            });
        }, 500);

        // Обработка кликов на заголовках аккордеонов
        document.querySelectorAll('.accordion-header2').forEach(header => {
            const acc = header.parentElement;

            header.addEventListener('click', () => {
                acc.classList.toggle('active');
                const content = acc.querySelector('.accordion-content2');
                if (acc.classList.contains('active')) {
                    content.style.display = 'block';
                    let delay = 0;
                    content.querySelectorAll('li').forEach((item, index) => {
                        setTimeout(() => {
                            item.style.display = 'list-item';
                        }, delay);
                        delay += 70;
                    });
                } else {
                    content.style.display = 'none';
                    content.querySelectorAll('li').forEach((item, index) => {
                        item.style.display = 'none';
                    });
                }
            });
        });
    }, []);

    return (
        <div
            className="window2"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left'
            }}
        >
            <p className="window_name"
               style={{
                   position: "fixed",
                   top: "-20px",
                   left: "4px",
                   fontSize: "12px",
                   color: "white",
                   opacity: "0.5",
               }}
            >Навыки</p>

            <div id="shapka2">
                <div id="grdnt2"></div>
            </div>

            <div id="avatar_position">
                <div id="avatar2">
                </div>
            </div>

            <h1>Навыки</h1>
            <h4>Привет, я Слава 👋<br />И я не просто непровозглашённый UX/UI-дизайнер, я – огромный любитель исследовать мир пользовательского опыта. Люблю исследовать грани дизайна, внедрять инновации и превосходить то, что было раньше (но только там, где это надо 😉)</h4>
            <hr />
            <h3>Карьерный трек и отраслевой опыт</h3>
            <p>А карьерного трека нет. С компаниями не работал. Только с частными лицами.</p>
            <div className="accordion2">
                <div className="accordion-header2">
                    <svg className="arrow-icon2" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.90039 11.8002L50.0004 88.2002L94.1004 11.8002H5.90039Z" />
                    </svg>
                    Отрасли
                </div>
                <div className="accordion-content2">
                    <ul>
                        <li>Образование</li>
                        <li>Розничная торговля</li>
                        <li>Ресторанный бизнес</li>
                        <li>Маркетинг</li>
                        <li>Медиа и развлечения</li>
                        <li>Недвижимость</li>
                        <li>Потребительские товары</li>
                        <li>Игровые платформы</li>
                    </ul>
                </div>
            </div>
            <hr />
            <h3>Функциональный опыт</h3>
            <div className="accordion2">
                <div className="accordion-header2">
                    <svg className="arrow-icon2" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.90039 11.8002L50.0004 88.2002L94.1004 11.8002H5.90039Z" />
                    </svg>
                    UX-исследования <p style={{ marginLeft: "12px", color: "black", opacity: "0.6" }}>(Использую, но не часто)</p>
                </div>
                <div className="accordion-content2">
                    <ul>
                        <li>Глубинные и JTBD-интервью;</li>
                        <li>Анализ кликов;</li>
                        <li>UX-аудит;</li>
                        <li>A/B;</li>
                        <li>Анализ обратной связи;</li>
                        <li>Eye-tracker;</li>
                        <li>5-секундный тест;</li>
                        <li>Краудсорсинговые платформы;</li>
                        <li>Яндекс Метрика и Google Analytics.</li>
                    </ul>
                </div>
            </div>
            <div className="accordion2">
                <div className="accordion-header2">
                    <svg className="arrow-icon2" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.90039 11.8002L50.0004 88.2002L94.1004 11.8002H5.90039Z" />
                    </svg>
                    Методологии
                </div>
                <div className="accordion-content2">
                    <ul>
                        <li>Атомарный дизайн;</li>
                        <li>↓ Умею искать респондентов;</li>
                        <li>CJM;</li>
                        <li>JTBD;</li>
                        <li>Персоны и пр.</li>
                    </ul>
                </div>
            </div>
            <div className="accordion2">
                <div className="accordion-header2">
                    <svg className="arrow-icon2" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.90039 11.8002L50.0004 88.2002L94.1004 11.8002H5.90039Z" />
                    </svg>
                    UX-артефакты <p style={{ marginLeft: "12px", color: "var(--text-primary)", opacity: "0.6" }}>(Использую часто)</p>
                </div>
                <div className="accordion-content2">
                    <ul>
                        <li>Информационная архитектура;</li>
                        <li>Сценарная концепция;</li>
                        <li>Варфреймы и LoFi;</li>
                        <li>Кликабельные прототипы.</li>
                    </ul>
                </div>
            </div>
            <div className="accordion2">
                <div className="accordion-header2">
                    <svg className="arrow-icon2" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.90039 11.8002L50.0004 88.2002L94.1004 11.8002H5.90039Z" />
                    </svg>
                    Инструменты
                </div>
                <div className="accordion-content2">
                    <ul>
                        <li>Figma;</li>
                        <li>Adobe Illustrator;</li>
                        <li>Adobe Photoshop;</li>
                        <li>Adobe After Effects;</li>
                        <li>JetBrains WebStorm;</li>
                        <li>Procreate;</li>
                        <li>Miro;</li>
                        <li>Power Point;</li>
                        <li>Google Docs;</li>
                        <li>Notion;</li>
                    </ul>
                </div>
            </div>
            <hr />
            <h3>А ещё умею в After Effects и 3д</h3>
            <p>Занимаюсь моушеном с 2016 года. В нынешнее время иногда делаю рекламные ролики и анимированные макеты своих интерфейсов для проектов</p>
            <hr />
            <h3>Code и no-code сайты</h3>
            <p>Знаю, что такое писать сайты. Поэтому, как говорил и в портфолио, <b>стараюсь максимально грамотно подходить к Hand-off'у разработчикам</b></p>
            <hr />
            <h3>Midjourney и DALL-e</h3>
            <p>Использую уместно и грамотно в своих работах с конца 2022 года</p>
        </div>
    );
};

export default Window2;
