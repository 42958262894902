// Window3.js
import React, { useState, useEffect } from 'react';
import './Window3.css';
import PoliceTape from './PoliceTape';

const Window3 = ({ offsetX, offsetY, zoom }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1000) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    }, [windowWidth]);

    return (
        <div
            className="window3"
            style={{
                transform: `translate(${offsetX * zoom}px, ${offsetY * zoom}px) scale(${zoom})`,
                transformOrigin: 'top left'
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <p className="window_name"
                style={{
                position: "fixed",
                top: "-20px",
                left: "4px",
                fontSize: "12px",
                color: "white",
                opacity: "0.5",
            }}>
                Чат-менеджер – 2024
            </p>
            <img
                src={require(isHovered ? './images/chat.png' : './images/transparent_chat.png')}
                className={`save_proportions`}
                alt="chat"
            />
            <PoliceTape />
            <div className="window3_inner" >
                <h1>Чат-менеджер</h1>
                <p>NDA, Voice Only</p>
            </div>
        </div>
    );
};

export default Window3;
