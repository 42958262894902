// LogoWithEffect.js
import React, { useState } from 'react';
import './LogoWithEffect.css';

const colors = ['white', '#ED4545', '#FF993B', '#FFDB5C', '#6DDE6A', '#8C9EFD', '#574AEA', '#61287C'];

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const LogoWithEffect = () => {
    const [colorIndex, setColorIndex] = useState(0);
    const [explosions, setExplosions] = useState([]);
    const [isBouncing, setIsBouncing] = useState(false);

    const handleLogoClick = () => {
        setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
        triggerExplosion();
        triggerBounce();
    };

    const triggerExplosion = () => {
        const newExplosion = {
            id: new Date().getTime(),
            particles: Array.from({ length: 20 }, () => ({
                id: new Date().getTime() + Math.random(),
                offsetX: getRandomInt(-20, 250),
                offsetY: getRandomInt(-20, 250),
                color: colors[Math.floor(Math.random() * colors.length)],
            })),
        };
        setExplosions((prevExplosions) => [...prevExplosions, newExplosion]);
        setTimeout(() => {
            setExplosions((prevExplosions) =>
                prevExplosions.filter((explosion) => explosion.id !== newExplosion.id)
            );
        }, 2000);

        // Добавьте этот таймер для возврата цвета логотипа в белый через 5 секунд
        setTimeout(() => {
            setColorIndex(0);
        }, 5000);
    };

    const triggerBounce = () => {
        setIsBouncing(true);
        setTimeout(() => {
            setIsBouncing(false);
        }, 500); // продолжительность bounce-анимации
    };

    return (
        <div className={`logo-container ${isBouncing ? 'bounce' : ''}`} onClick={handleLogoClick}>
            <svg
                className="logo"
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ fill: colors[colorIndex] }}
            >
                <path d="M14.6095 7.23334L18.2823 1.55993L15.7717 0L11.4888 6.61591C10.9818 6.44671 10.5128 6.25759 10.0633 6.0651C9.86547 5.98036 9.66244 5.88982 9.45417 5.79694C8.22513 5.24886 6.81333 4.61927 5.2027 4.61927C3.15234 4.61927 1.41826 5.05619 0 5.70642V9.06694C1.15967 8.25048 2.87411 7.53185 5.2027 7.53185C6.1213 7.53185 6.873 7.86097 8.12084 8.4073L8.12097 8.40736C8.35291 8.50891 8.602 8.61797 8.87248 8.73381C9.18488 8.8676 9.51248 9.00265 9.85886 9.13365L6.82309 13.823C6.30692 13.6991 5.76702 13.6218 5.2027 13.6218C3.15234 13.6218 1.41826 14.0587 0 14.7089V18.0694C1.13708 17.2689 2.80754 16.5624 5.06735 16.5352L1.24469 22.4401L3.75531 24L8.04352 17.376C8.06908 17.3871 8.09485 17.3984 8.12084 17.4098L8.12097 17.4099C8.35291 17.5114 8.602 17.6205 8.87248 17.7363C10.5198 18.4418 12.59 19.1821 15.6081 19.1821C17.2391 19.1821 18.7132 18.7755 20 18.1643V14.7897C18.7352 15.6645 17.25 16.2696 15.6081 16.2696C13.1533 16.2696 11.5072 15.686 10.0633 15.0676C9.92923 15.0102 9.79276 14.9501 9.65389 14.8884L12.8476 9.95504C13.6726 10.0947 14.586 10.1796 15.6081 10.1796C17.2391 10.1796 18.7132 9.77303 20 9.16181V5.78719C18.7352 6.66197 17.25 7.26707 15.6081 7.26707C15.2595 7.26707 14.9272 7.2553 14.6095 7.23334Z" />
            </svg>
            {explosions.map((explosion) => (
                <div key={explosion.id} style={{left: '23px', top: '8px'}} className="explosion-container">
                    {explosion.particles.map((particle) => (
                        <div
                            key={particle.id}
                            className="particle"
                            style={{
                                '--offset-x': `${particle.offsetX}px`,
                                '--offset-y': `${particle.offsetY}px`,
                                '--color': particle.color,
                            }}
                        >
                            <svg
                                width="20"
                                height="24"
                                viewBox="0 0 20 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ fill: 'var(--color)' }}
                            >
                                <path d="M14.6095 7.23334L18.2823 1.55993L15.7717 0L11.4888 6.61591C10.9818 6.44671 10.5128 6.25759 10.0633 6.0651C9.86547 5.98036 9.66244 5.88982 9.45417 5.79694C8.22513 5.24886 6.81333 4.61927 5.2027 4.61927C3.15234 4.61927 1.41826 5.05619 0 5.70642V9.06694C1.15967 8.25048 2.87411 7.53185 5.2027 7.53185C6.1213 7.53185 6.873 7.86097 8.12084 8.4073L8.12097 8.40736C8.35291 8.50891 8.602 8.61797 8.87248 8.73381C9.18488 8.8676 9.51248 9.00265 9.85886 9.13365L6.82309 13.823C6.30692 13.6991 5.76702 13.6218 5.2027 13.6218C3.15234 13.6218 1.41826 14.0587 0 14.7089V18.0694C1.13708 17.2689 2.80754 16.5624 5.06735 16.5352L1.24469 22.4401L3.75531 24L8.04352 17.376C8.06908 17.3871 8.09485 17.3984 8.12084 17.4098L8.12097 17.4099C8.35291 17.5114 8.602 17.6205 8.87248 17.7363C10.5198 18.4418 12.59 19.1821 15.6081 19.1821C17.2391 19.1821 18.7132 18.7755 20 18.1643V14.7897C18.7352 15.6645 17.25 16.2696 15.6081 16.2696C13.1533 16.2696 11.5072 15.686 10.0633 15.0676C9.92923 15.0102 9.79276 14.9501 9.65389 14.8884L12.8476 9.95504C13.6726 10.0947 14.586 10.1796 15.6081 10.1796C17.2391 10.1796 18.7132 9.77303 20 9.16181V5.78719C18.7352 6.66197 17.25 7.26707 15.6081 7.26707C15.2595 7.26707 14.9272 7.2553 14.6095 7.23334Z" />
                            </svg>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LogoWithEffect;
