import React, { useState, useEffect } from 'react';
import './Header.css';
import LogoWithEffect from "./LogoWithEffect";

const Header = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 520);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 520);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleShare = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000); // Уведомление будет отображаться 3 секунды
        });
    };

    return (
        <header className="header">
            <div className="header-content">
                < LogoWithEffect className="logo"/>
                <a
                    href="https://t.me/betray24"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-link"
                >
                    t.me/betray24 /
                </a>
                <span className="header-title"> napdesign
                    <svg className="arrow_down" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.5L5 4.5L9 1.5" stroke="white" strokeWidth="1.5"/>
                    </svg>
                </span>
                <div className="magnetic">
                    <div className="magnetic_emission">
                        <a className="magnetic_content share-link" onClick={handleShare}>
                            {isMobile ? (
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 11C18.1046 11 19 10.1046 19 9C19 7.89543 18.1046 7 17 7C15.8954 7 15 7.89543 15 9C15 10.1046 15.8954 11 17 11Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 16C10.1046 16 11 15.1046 11 14C11 12.8954 10.1046 12 9 12C7.89543 12 7 12.8954 7 14C7 15.1046 7.89543 16 9 16Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11 15L15 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 10L11 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            ) : (
                                'Поделиться'
                            )}
                        </a>
                    </div>
                </div>
                {showNotification && <div className="notification">Ссылка на данную страницу была скопирована</div>}
            </div>
        </header>
    );
};

export default Header;
