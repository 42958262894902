import React, { useRef, useEffect, useState } from 'react';
import BackgroundDots from './BackgroundDots';
import Window1 from './Window1';
import Window2 from './Window2';
import Window3 from './Window3';
import Window4 from './Window4';
import Window5 from './Window5';
import OtherPortfolio from './OtherPortfolio';
import './CanvasContainer.css';

const CanvasContainer = ({ currentWindow }) => {
    const containerRef = useRef(null);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startDragX, setStartDragX] = useState(0);
    const [startDragY, setStartDragY] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [initialDistance, setInitialDistance] = useState(0);
    const [initialZoom, setInitialZoom] = useState(1);

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsDragging(true);
        setStartDragX(e.clientX - offsetX);
        setStartDragY(e.clientY - offsetY);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const newOffsetX = e.clientX - startDragX;
            const newOffsetY = e.clientY - startDragY;
            setOffsetX(newOffsetX);
            setOffsetY(newOffsetY);
        }
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleScroll = (e) => {
        e.preventDefault();
        if (e.ctrlKey) {
            setZoom((prevZoom) => Math.min(Math.max(0.5, prevZoom - e.deltaY * 0.001), 3));
        } else {
            setOffsetX((prevOffsetX) => prevOffsetX - e.deltaX);
            setOffsetY((prevOffsetY) => prevOffsetY - e.deltaY);
        }
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            e.preventDefault();
            setIsDragging(true);
            setStartDragX(e.touches[0].clientX - offsetX);
            setStartDragY(e.touches[0].clientY - offsetY);
        } else if (e.touches.length === 2) {
            e.preventDefault();
            setIsDragging(false); // Disable dragging when zooming
            const distance = Math.sqrt(
                Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) +
                Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2)
            );
            setInitialDistance(distance);
            setInitialZoom(zoom);
        }
    };

    const handleTouchMove = (e) => {
        if (isDragging && e.touches.length === 1) {
            e.preventDefault();
            const newOffsetX = e.touches[0].clientX - startDragX;
            const newOffsetY = e.touches[0].clientY - startDragY;
            setOffsetX(newOffsetX);
            setOffsetY(newOffsetY);
        } else if (e.touches.length === 2) {
            e.preventDefault();
            const distance = Math.sqrt(
                Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) +
                Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2)
            );
            const newZoom = Math.min(Math.max(0.5, initialZoom * (distance / initialDistance)), 3);
            setZoom(newZoom);
        }
    };

    const handleTouchEnd = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('mousedown', handleMouseDown);
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseup', handleMouseUp);
        container.addEventListener('mouseleave', handleMouseUp);
        container.addEventListener('wheel', handleScroll);

        container.addEventListener('touchstart', handleTouchStart, { passive: false });
        container.addEventListener('touchmove', handleTouchMove, { passive: false });
        container.addEventListener('touchend', handleTouchEnd);

        return () => {
            container.removeEventListener('mousedown', handleMouseDown);
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseup', handleMouseUp);
            container.removeEventListener('mouseleave', handleMouseUp);
            container.removeEventListener('wheel', handleScroll);

            container.removeEventListener('touchstart', handleTouchStart);
            container.removeEventListener('touchmove', handleTouchMove);
            container.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging, offsetX, offsetY, zoom, initialDistance, initialZoom]);

    useEffect(() => {
        setOffsetX(0);
        setOffsetY(0);
    }, [currentWindow]);

    const renderWindow = () => {
        const windowProps = { offsetX, offsetY, zoom };

        switch (currentWindow) {
            case "Обо мне":
                return <Window1 {...windowProps} />;
            case "Навыки":
                return <Window2 {...windowProps} />;
            case "Т-Образование – 2024":
                return <Window5 {...windowProps} />;
            case "Чат-менеджер – 2024":
                return <Window3 {...windowProps} />;
            case "Amore Mio – 2023":
                return <Window4 {...windowProps} />;
            case "SCoffee – 2023":
                return <OtherPortfolio {...windowProps} />;
            default:
                return <Window1 {...windowProps} />;
        }
    };

    return (
        <div ref={containerRef} className="canvas-container">
            <BackgroundDots offsetX={offsetX} offsetY={offsetY} zoom={zoom} />
            {renderWindow()}
        </div>
    );
};

export default CanvasContainer;
